//定义时间显示的方法
export const timeToString = (time: number, type = 1): string => {
    //type1--00:00,type2--00:00.0,type3--0:00:00
    const prefix = time < 0 ? '-' : '';
    time = Math.abs(time);
    const minutes = ('' + Math.floor(time / 60)).padStart(2, '0');
    const seconds = ('' + Math.floor(time % 60)).padStart(2, '0');
    const decimal = ('' + (time % 1) * 100).substring(0, 1);
    return `${prefix}${minutes}:${seconds}${type === 2 ? '.' + decimal : ''}`;
};

//定义歌手显示的方法
export const authorToString = (author: string | string[]) => {
    if (author === undefined) {
        return '';
    }
    if (Array.isArray(author)) {
        return author.join('&');
    } else {
        return '' + author;
    }
};

export default {
    timeToString,
    authorToString,
};
